import {
	initialState
} from './index';

export default {
	SET_IMPORTS: (state, data) => {
		state.extension.imports = data;
	},
	APPEND_IMPORTS: (state, data) => {
		state.extension.imports = [...state.extension.imports, ...data];
	},
	RESET_STATE: (state) => {
		const iState = initialState();
		for (const prop in state) {
			if (typeof iState[prop] !== 'undefined' && prop !== 'token') {
				state[prop] = iState[prop];
			}
		}
	},
	SET_START: (state, start) => {
		state.extension.start = start;
	},
	SET_FINISHED: (state, status) => {
		state.extension.finished = status;
	},
	SAVE_EXTENSION_REPORT: (state, data) => {
		state.extension.reports[data.id] = data.data;
	}
};