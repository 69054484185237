import {
    getExtensionImportHistory,
    requestProcessing,
    downloadExtensionReport,
    connectToShop,
    shopData,
    supplierCategories,
    shopCategories,
    loadUserShops,
    getExperimentalInterfaceStatus,
    shopProductOptions,
    shopProductCharacteristics,
    storeShopMappedCategories,
    storeShopMappedCategoryCharacteristics,
    shopProductManufacturers,
    products,
    saveSettings,
    getOrdersStatuses,
    saveMappedOrderStatuses,
    getOrdersPaymentsMethods,
    saveMappedOrderPayments,
    savePaymentModules,
    syncProduct,
    syncPaymentModules,
    getRequiredResources,
    saveShippingMethods,
    getCategoryCommission,
    getCurrencies,
} from '@/api/integrations';

import { getCategCharacts } from '@/api/publicare';
export default {
    GetExtensionImportHistory: ({ commit, state }, loadMore) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getExtensionImportHistory({
                    start: state.extension.start,
                });
                if (res.message == null) return reject(new Error());
                if (loadMore) commit('APPEND_IMPORTS', res.message);
                commit('SET_IMPORTS', res.message);
                if (res.message.length < state.extension.limit) commit('SET_FINISHED', true);
                return resolve();
            } catch (err) {
                return reject(err);
            }
        });
    },
    RequestProcessing: ({ commit, store }, opts) => {
        return requestProcessing(opts);
    },
    DownloadExtensionReport: ({ commit, state }, exportId) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (typeof state.extension.reports[exportId] != 'undefined')
                    return resolve(state.extension.reports[exportId]);
                const res = await downloadExtensionReport({
                    exportId,
                });
                if (res.message == null) return reject(new Error());
                commit('SAVE_EXTENSION_REPORT', {
                    id: exportId,
                    data: res.message,
                });
                resolve(res.message);
            } catch (err) {
                return reject(err);
            }
        });
    },
    getUserShops: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await loadUserShops(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    showExperimentalInferface: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getExperimentalInterfaceStatus(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    checkConnectionToShop: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await connectToShop(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    getRequiredResources: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getRequiredResources(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    getShopData: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await shopData(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    getShopCategories: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await shopCategories(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    // Shop Options = Attributes for Prestashop
    getShopProductOptions: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await shopProductOptions(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    // Shop Product Characteristics = Features for Prestashop
    getShopProductCharacteristics: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await shopProductCharacteristics(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    // Shop Products Manufacturers
    getShopProductManufacturers: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await shopProductManufacturers(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    // Shop Product Characteristics = Features for Prestashop
    storeShopMappedCategories: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await storeShopMappedCategories(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    storeShopMappedCategoryCharacteristics: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await storeShopMappedCategoryCharacteristics(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    // TODO: Create a module "publicare" and register just this two actions here -- speak with Alin about that
    getSupplierCategories: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await supplierCategories(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    getCategCharacts: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCategCharacts(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    getProducts: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await products(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    saveSettings: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await saveSettings(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    getOrdersStatuses: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getOrdersStatuses(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    saveMappedOrderStatuses: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await saveMappedOrderStatuses(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    getOrdersPaymentsMethods: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getOrdersPaymentsMethods(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    saveMappedOrderPayments: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await saveMappedOrderPayments(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    savePaymentModules: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await savePaymentModules(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    syncPaymentModules: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await syncPaymentModules(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    syncProduct: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await syncProduct(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    saveShippingMethods: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await saveShippingMethods(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    getCategoryCommission: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCategoryCommission(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },

    getCurrencies: (context, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getCurrencies(payload);
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
};

