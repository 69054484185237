import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = () => ({
	extension: {
		imports: [],
		finished: false,
		start: 0,
		limit: 50,
		reports: {}
	}
});

export function createIntegrationsStore() {
	return {
		namespaced: true,
		state: initialState,
		actions,
		getters,
		mutations,
	};
}