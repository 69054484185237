import fetch from '@/utils/fetch';

export function getExtensionImportHistory(data) {
    return fetch({
        url: '/ext/getImportHistory',
        method: 'post',
        data,
    });
}

export function requestProcessing(data) {
    return fetch({
        url: '/ext/requestProcessing',
        method: 'post',
        data,
    });
}

export function downloadExtensionReport(data) {
    return fetch({
        url: '/ext/downloadExtensionReport',
        method: 'post',
        data,
    });
}

export function loadUserShops(data) {
    return fetch({
        url: '/integrations/getUserShops',
        method: 'post',
        data: {
            ...data,
        },
    });
}

export function getExperimentalInterfaceStatus(data) {
    return fetch({
        url: '/admininfo/showExperimentalInterface',
        method: 'post',
        data: {
            ...data,
        },
    });
}

export function connectToShop(data) {
    return fetch({
        url: '/integrations/connect',
        method: 'post',
        data,
    });
}

export function getRequiredResources(data) {
    return fetch({
        url: '/integrations/getRequiredResources',
        method: 'post',
        data,
    });
}

export function shopData(data) {
    return fetch({
        url: '/integrations/shopData',
        method: 'post',
        data: {
            ...data,
        },
    });
}

export function supplierCategories() {
    return fetch({
        url: '/import/getSupplierCategories',
        method: 'post',
    });
}

export function shopCategories(data) {
    return fetch({
        url: '/integrations/getShopCategories',
        method: 'post',
        data: { ...data },
    });
}

export function shopProductOptions(data) {
    return fetch({
        url: '/integrations/getProductOptions',
        method: 'post',
        data: { ...data },
    });
}

export function shopProductCharacteristics(data) {
    return fetch({
        url: '/integrations/getProductCharacteristics',
        method: 'post',
        data: { ...data },
    });
}

export function shopProductManufacturers(data) {
    return fetch({
        url: '/integrations/getShopProductManufacturers',
        method: 'post',
        data: { ...data },
    });
}

export function storeShopMappedCategories(data) {
    return fetch({
        url: '/integrations/storeShopMappedCategories',
        method: 'post',
        data,
    });
}

export function storeShopMappedCategoryCharacteristics(data) {
    return fetch({
        url: '/integrations/storeShopMappedCategoryCharacteristics',
        method: 'post',
        data,
    });
}

export function products(data) {
    return fetch({
        url: '/integrations/products',
        method: 'post',
        data,
    });
}

export function saveSettings(data) {
    return fetch({
        url: '/integrations/saveSettings',
        method: 'post',
        data,
    });
}

export function getOrdersStatuses(data) {
    return fetch({
        url: '/integrations/getOrdersStatuses',
        method: 'post',
        data,
    });
}

export function saveMappedOrderStatuses(data) {
    return fetch({
        url: '/integrations/saveMappedOrderStatuses',
        method: 'post',
        data,
    });
}

export function getOrdersPaymentsMethods(data) {
    return fetch({
        url: '/integrations/getOrdersPaymentsMethods',
        method: 'post',
        data,
    });
}

export function saveMappedOrderPayments(data) {
    return fetch({
        url: '/integrations/saveMappedOrderPayments',
        method: 'post',
        data,
    });
}

export function savePaymentModules(data) {
    return fetch({
        url: '/integrations/savePaymentModules',
        method: 'post',
        data,
    });
}

export function syncPaymentModules(data) {
    return fetch({
        url: '/integrations/syncPaymentModules',
        method: 'post',
        data,
    });
}

export function syncProduct(data) {
    return fetch({
        url: '/integrations/syncProduct',
        method: 'post',
        data,
    });
}

export function saveShippingMethods(data) {
    return fetch({
        url: '/integrations/saveShippingMethods',
        method: 'post',
        data,
    });
}

export function getCategoryCommission(data) {
    return fetch({
        url: '/integrations/getCategoryCommission',
        method: 'post',
        data,
    });
}

export function getCurrencies() {
    return fetch({
        url: '/integrations/getCurrencies',
        method: 'post',
    });
}
