<template>
	<div class="app-container">
		<dd-card class="box-card" :title="'Informatii extensie'">
			<Box :type="'info'" :active="true">
				<div slot="box-body">
					<ul class="rawUl">
						<li><strong>Momentan extensia este disponibila doar pentru browserul Google Chrome.</strong>
						</li>
						<li><strong>Versiune minima Google Chrome: 45.00</strong>.</li>
					</ul>
				</div>
			</Box>
			<p>Cu ajutorul extensiei pentru Google Chrome puteti importa produse din marketplace-ul competitorului
				intr-un timp foarte scurt.</p>
			<a href="https://marketplaceimporter.now.sh/" target="_blank" class="cel-color">Link documentatie & ghid de
				instalare si utilizare</a>
			<div class="separator"></div>
			<div class="title">Importa produsele in trei pasi simpli:</div>
			<ul>
				<li><strong>Pasul 1.</strong> Genereaza o cerere de export.</li>
				<li><strong>Pasul 2.</strong> Asteapta emailul care iti confirma cate produse au fost acceptate.</li>
				<li><strong>Pasul 3.</strong> Imediat dupa aprobare, activeaza produsele care vrei sa apara pe site si
					seteaza-le stocul real.</li>
			</ul>
		</dd-card>
		<div class="separator">
			<dd-card class="box-card" :title="'Istoric import'">
				<el-table :data="importsList" style="width: 100%" :default-expand-all="true"
					row-class-name="export-row">
					<el-table-column type="expand">
						<template slot-scope="props">
							<div v-if="props.row.obs && props.row.obs !== ''">
								<div class="title">Observatii</div>
								<div class="obs">{{props.row.obs}}</div>
							</div>
							<div class="title">Istoric procesari</div>
							<el-table :data="props.row.processes" style="width: 100%"
								header-cell-class-name="history-row">
								<el-table-column prop="id" label="ID" width="50">
								</el-table-column>
								<el-table-column prop="date_start" label="Data start">
								</el-table-column>
								<el-table-column prop="date_end" label="Data finalizare">
									<template slot-scope="scope" v-if="parseInt(scope.row.parsed) === 1">
										{{scope.row.date_end}}
									</template>
								</el-table-column>
								<el-table-column prop="updated" label="Acceptate">
								</el-table-column>
								<el-table-column prop="refused" label="Refuzate">
								</el-table-column>
								<el-table-column prop="status" label="Status">
									<template slot-scope="scope">
										<strong>{{parseInt(scope.row.parsed) === 1 ? 'Finalizat' : 'In asteptare'}}</strong>
									</template>
								</el-table-column>
								<el-table-column prop="status" label="Actiuni">
									<template slot-scope="scope">
										<el-button v-if="scope.row.refused > 0" @click="downloadReport(scope.row.id)"
											size="mini" plain type="primary">
											Raport refuzate</el-button>
									</template>
								</el-table-column>
							</el-table>
						</template>
					</el-table-column>
					<el-table-column label="ID" prop="id" width="75px">
					</el-table-column>
					<el-table-column label="C. Export">
						<template slot-scope="scope">
							<strong>[{{scope.row.categ_id}}] {{scope.row.gameCategoryName}}</strong>
						</template>
					</el-table-column>
					<el-table-column label="C. Import">
						<template slot-scope="scope">
							<strong>{{scope.row.celCategoryName}}</strong>
						</template>
					</el-table-column>
					<el-table-column label="Status">
						<template slot-scope="scope">
							<ImportStatus :status="scope.row.status"></ImportStatus>
						</template>
					</el-table-column>
					<el-table-column label="Ultima actualizare" prop="last_update">
					</el-table-column>
					<el-table-column label="Actiuni">
						<template slot-scope="scope">
							<el-button :disabled="parseInt(scope.row.status) !== 3 && parseInt(scope.row.status) !== -1"
								@click="requestProcess(scope.row.id)" size="mini" plain type="primary">
								Reprocesare</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="separator"></div>
				<el-button size="small" v-if="!finished" type="primary" class="center" @click="loadMore">Incarca mai
					multe</el-button>
			</dd-card>
			<ImportReport @closeModal="handleImportReportClose" :importProblems="reportModalData"
				:visible="reportModal"></ImportReport>
		</div>
	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex';
	import {
		createIntegrationsStore
	} from '@/store/modules/integrations/index.js';
	const DDCard = () => import('@/components/DDCard');
	const ImportStatus = () => import('./components/ImportStatus');
	const ImportReport = () => import('./components/importReport');
	const Box = () =>
		import('vue-info-box-element');
	export default {
		components: {
			'dd-card': DDCard,
			Box,
			ImportStatus,
			ImportReport
		},
		data() {
			return {
				reportModal: false,
				reportModalData: []
			};
		},
		methods: {
			getImportHistory(loadMore = false) {
				this.$store.dispatch('integrations/GetExtensionImportHistory', loadMore)
					.then().catch(() => {});
			},
			requestProcess(id) {
				this.$confirm('Fisierul va fi din descarcat si procesat. Continuati?', 'Warning', {
						confirmButtonText: 'Da',
						cancelButtonText: 'Nu',
						type: 'warning'
					})
					.then(() => {
						return this.$store.dispatch('integrations/RequestProcessing', {
							id
						});
					})
					.then(() => this.sbMsg({
						type: 'success',
						message: 'Cererea a fost inregistrata. Veti primi notificare pe email cand procesul este complet.',
					}))
					.then(() => this.getImportHistory())
					.catch(() => {});
			},
			loadMore() {
				this.$store.commit('integrations/SET_START', this.start + this.limit);
				this.getImportHistory(true);
			},
			async downloadReport(id) {
				try {
					const data = await this.$store.dispatch('integrations/DownloadExtensionReport', id);
					this.showReportModal(data);
				} catch (err) {}
			},
			showReportModal(data) {
				this.reportModal = true;
				this.reportModalData = data;
			},
			handleImportReportClose() {
				this.reportModal = false;
				this.reportModalData = [];
			}
		},
		computed: {
			...mapState({
				importsList: (state) => state.integrations.extension.imports,
				limit: (state) => state.integrations.extension.limit,
				start: (state) => state.integrations.extension.start,
				finished: (state) => state.integrations.extension.finished
			})
		},
		mounted() {
			this.getImportHistory();
		},
		beforeCreate() {
			this.$store.registerModule('integrations', createIntegrationsStore());
		},
		beforeDestroy() {
			this.$store.unregisterModule('integrations');
		}
	};
</script>
<style lang="scss" scoped>
	.tabs {
		img {
			vertical-align: middle;
		}
	}

	.title {
		font-size: 15px;
	}

	.center {
		text-align: center;
		margin: 0 auto;
		width: 200px;
		display: block;
	}

	::v-deep .export-row {
		background: oldlace;
	}

	::v-deep .history-row {
		background: #FFF;
	}

	.obs {
		font-weight: 700;
		color: red;
		font-size: 13px;
		margin-bottom: 10px;
	}
</style>